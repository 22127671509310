<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <component
    :is="currentComponent"
    :item="item"
    v-bind="useMapAttrs($attrs)"
  />
</template>

<script setup>
import { computed } from 'vue'
import CloudTagCccText from './CloudTagCccText.vue'
import CloudTagCccImage from './CloudTagCccImage.vue'
import CloudTagSlot from './CloudTagSlot.vue'
import { useMapAttrs } from 'public/src/pages/common/vuex-composition.js'

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  }
})

const currentComponent = computed(() => {
  if (props.item.cccConfig?.cccType === 'slot') return CloudTagSlot

  return props.item.cccConfig?.displayType == 1 ? CloudTagCccImage : CloudTagCccText
})
</script>
